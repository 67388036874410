<template>
  <div class="container">
    <header>{{ $t('register').title }}</header>
    <div class="content">
      <div class="nav">
        <div
          class="item"
          @click="RegeistType = 'Account'"
          v-if="RegisterSelect.indexOf('Account') !== -1"
          :class="{ RegeistType: RegeistType === 'Account' }"
        >
          {{ $t('register').amount }}
        </div>
        <div
          class="item"
          v-if="RegisterSelect.indexOf('Mailbox') !== -1"
          @click="RegeistType = 'Mailbox'"
          :class="{ RegeistType: RegeistType === 'Mailbox' }"
        >
          {{ $t('register').mailbox }}
        </div>
      </div>
      <div v-if="RegeistType === 'Mailbox'">
        <div class="item">
          <van-field
            v-model="UserName"
            :placeholder="$t('register').placeholder"
          />
        </div>
        <div class="item" v-if="basicConfig.IsEmailVerify">
          <van-field
            v-model="EmailCode"
            :placeholder="$t('register').placeholder4"
          >
            <template #button>
              <span
                style="color: #e143ac"
                @click="handleClick"
                v-if="countDownSec === 60"
                >{{ tips }}</span
              >
              <span v-else style="color: #fff">{{ countDownSec }} s</span>
            </template>
          </van-field>
        </div>
      </div>
      <div v-else>
        <div class="item">
          <van-field
            v-model="UserName"
            :placeholder="$t('register').placeholder0"
          />
        </div>
      </div>

      <div class="item">
        <van-field
          v-model="Password"
          type="password"
          :placeholder="$t('register').placeholder1"
        >
          <template #right-icon>
            <img src="@/assets/new/password.png" class="pwd-icon" alt="" />
          </template>
        </van-field>
      </div>
      <div class="item">
        <van-field
          v-model="subPassword"
          type="password"
          :placeholder="$t('register').placeholder2"
        >
          <template #right-icon>
            <img src="@/assets/new/password.png" class="pwd-icon" alt="" />
          </template>
        </van-field>
      </div>
      <div class="item">
        <van-field
          v-model="InvitationCode"
          :placeholder="$t('register').placeholder3"
        />
      </div>
      <div class="agree">
        <van-checkbox icon-size="15" v-model="checked" shape="square">{{
          $t('register').agree
        }}</van-checkbox>
        <span @click="goXY">《{{ $t('register').xy }}》</span>
      </div>
      <van-button color="#e143ac" block @click="handleSub">{{
        $t('register').qr
      }}</van-button>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import { Token } from '@/utils/mm'
import { getUrlParam } from '@/utils/validate'
import { EventBus } from '@/utils/event-bus'
export default {
  data() {
    return {
      RegeistType: 'Mailbox',
      subPassword: '',
      Password: '',
      InvitationCode: '',
      UserName: '',
      EmailCode: '',
      checked: true,
      tips: this.$t('register').send,
      countDownSec: 60,
      RegisterSelect: ''
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang'])
  },
  mounted() {
    this.InvitationCode = getUrlParam('InvitationCode')
    this.RegeistType = this.basicConfig.RegisterSelect[0]
    this.RegisterSelect = this.basicConfig.RegisterSelect.join(',')
  },
  methods: {
    goXY() {
      let info = ''
      if (this.lang === 'zh') {
        info = this.basicConfig.Content
      } else if (this.lang === 'ty') {
        info = this.basicConfig.ContentThai
      } else if (this.lang === 'jp') {
        info = this.basicConfig.ContentJapanese
      } else if (this.lang === 'en') {
        info = this.basicConfig.ContentEnglish
      } else if (this.lang === 'yn') {
        info = this.basicConfig.ContentIndonesia
      } else if (this.lang === 'hy') {
        info = this.basicConfig.ContentKorea
      }
      this.$router.push({
        name: 'Detail',
        query: {
          title: this.$t('register').xy,
          info
        }
      })
    },
    async handleLogin() {
      let res = await userApi.getRefreshToken({
        username: this.UserName,
        password: this.Password
      })
      res = await userApi.login(res.Token)
      Token.set(res.Token)
      EventBus.$emit('Login')

      setTimeout(() => {
        this.$router.push({ name: 'Home' })
      }, 1000)
    },
    async handleClick() {
      if (this.timer || !this.checkEmail()) return
      await userApi.sendEmail({ email: this.UserName })
      this.timer = setInterval(() => {
        this.countDownSec--
        if (this.countDownSec === 0) {
          this.countDownSec = 60
          this.tips = this.$t('register').Resend
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    checkForm() {
      if (this.RegeistType === 'Mailbox') {
        if (!this.checkEmail()) {
          return false
        }
        if (!this.EmailCode && this.basicConfig.IsEmailVerify == true) {
          this.$toast({
            message: this.$t('register').placeholder4,
            position: 'bottom'
          })
          return false
        }
      } else {
        if (!this.UserName) {
          this.$toast({
            message: this.$t('register').placeholder0,
            position: 'bottom'
          })
          return false
        }
      }

      if (this.Password.length < 6) {
        this.$toast({
          message: this.$t('register').message,
          position: 'bottom'
        })
        return false
      }
      if (this.subPassword.length < 6) {
        this.$toast({
          message: this.$t('register').message1,
          position: 'bottom'
        })
        return false
      }
      if (this.subPassword !== this.Password) {
        this.$toast({
          message: this.$t('register').message2,
          position: 'bottom'
        })
        return false
      }
      if (!this.checked) {
        this.$toast({
          message: this.$t('register').message3,
          position: 'bottom'
        })
        return false
      }
      return true
    },
    checkEmail() {
      if (this.UserName === '') {
        this.$toast({
          message: this.$t('register').placeholder,
          position: 'bottom'
        })
        return false
      }
      const reg = new RegExp(
        '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'
      )
      if (!reg.test(this.UserName.toLowerCase())) {
        this.$toast(this.$t('register').placeholder5)
        return false
      } else {
        return true
      }
    },
    async handleSub() {
      if (!this.checkForm()) return
      const form = {
        UserName: this.UserName,
        EmailCode: this.EmailCode,
        RegeistType: this.RegeistType,
        Password: this.Password,
        InvitationCode: this.InvitationCode
      }
      await userApi.register(form)
      this.$toast(this.$t('register').success)
      setTimeout(() => {
        this.handleLogin()
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  header {
    background-color: rgb(16, 32, 48);
    color: white;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
  }
  .pwd-icon {
    width: 15px;
    height: 15px;
  }
  .content {
    padding: 30px 20px 20px;
    .nav {
      display: flex;
      margin-bottom: 10px;
      .item {
        font-size: 16px;
        height: 40px;
        width: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .RegeistType {
        color: #e143ac;
        position: relative;
        &::after {
          content: '';
          width: 20px;
          height: 4px;
          background: #e143ac;
          position: absolute;
          bottom: 0;
        }
      }
    }
    .van-cell {
      background: none;
      padding: 10px 0;
      border-bottom: 1px solid #3e536b;
      & /deep/ .van-field__control {
        color: #fff;
      }
    }
    .agree {
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
        color: #e143ac;
      }
    }
    .van-checkbox {
      margin: 15px 0;
      font-size: 12px;
      & /deep/ .van-checkbox__label {
        color: #fff;
      }
    }
    .van-button {
      height: 37px;
      margin-top: 45px;
    }
  }
}
</style>
